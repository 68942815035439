// Constants based on environment variables or platform (web/mobile)
// Checkout web or mobile branch to use the correct environment variables

const base = process.env.API_BASE_URL || 'http://localhost:8000';
export const baseApi = process.env.NODE_ENV === 'development' ? '/api' : `${base}/api`;
export const baseUrl = process.env.NODE_ENV === 'development' ? '/' : `/shop/`;
const domain = 'kindo.co.nz';
const domainParts = domain.split('.');
// Derives the subdomain dynamically based on the current URL
// Example value of platformEnv:
// For https://dev.shop.kindo.co.nz/, platformEnv will be dev.shop.
// For https://dev.partner.kindo.co.nz/, platformEnv will be dev.partner.
// For https://shop.kindo.co.nz/, platformEnv will be shop.
// For https://partner.kindo.co.nz/, platformEnv will be partner.
export const platformEnv = window.location.hostname
  .split('.') // Split the hostname into parts
  .slice(0, window.location.hostname.split('.').length - domainParts.length) // Exclude the base domain parts
  .join('.'); // Join the remaining parts to get the subdomain

// File/directory utilities
export const productImageDirectory = baseUrl + 'upload/images/products';
export const getServiceImageDirectory = ({ schoolPath, serviceId }: { schoolPath: string; serviceId: string; }) => baseUrl + `upload/images/school/${schoolPath}/services/${serviceId}/`;
export const getSupplierImageSrc = ({ supplierId }: { supplierId: string; }) => baseUrl + `upload/images/supplier/${supplierId}/pdf_logo/${supplierId}.jpg`;
export const getDeliveryInfoUrl = ({ schoolPath, serviceId }: { schoolPath: string; serviceId: string; }) => `${baseUrl}upload/pdf/school_misc/${schoolPath}/services/${serviceId}/delivery_info.pdf`;
export const getSchoolLogo = ({ schoolPath }: { schoolPath: string; }) => `${baseUrl}upload/images/school/${schoolPath}/${schoolPath}_label_logo.jpg`;
export const kindoLogoUrl = `${baseUrl}img/logo/mykindo/standard/myKindo_360px.png`;
export const getDonationReceiptsUrl = ({ purchaseId }: { purchaseId: string; }) => `${baseApi}/FAMILY/donation_receipts?type=html&pid=${purchaseId}`;
export const getTemporaryLogo = ({ imageName }: { imageName: string; }) => `${baseUrl}upload/images/temp/${imageName}`;

// Privacy links
export const privacyTerms = 'https://kindo.co.nz/privacy-and-terms/#privacy-terms';
export const customerTerms = 'https://kindo.co.nz/privacy-and-terms#customer-terms';

// App links
export const googleStore = 'https://play.google.com/store/apps/details?id=nz.co.tgcl.mykindo&hl=en_NZ&gl=US';
export const appStore = 'https://apps.apple.com/nz/app/mykindo/id1445622107';

// Poli disabled for schools
export const disabledSchoolsForPoli = ['Middleton Grange School']

// Support links
export const amlSupportLink = "https://support.mykindo.co.nz/portal/en/kb/articles/aml-and-kindo";