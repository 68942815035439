/*
* Helpers for loading and setting page parameters
*/

export const parsePageParameters = (search?: string): Map<string, string> => {
  /// Adapted from: https://stackoverflow.com/a/8486188/7045733
  if (!search) search = window.location.search;
  var query = search.substr(1);
  var result = new Map();
  query.split("&").forEach(function (part) {
    var item = part.split("=");

    if (item[0] === '') {
      return
    }

    result.set(item[0], decodeURIComponent(item.slice(1).join("=")));
  });
  return result;
}

export const SHOP_OR_SCHOOL_ID_PAGE_PARAM_NAMES = ['shop', 'shop_id', 'school_id', 'school'];
export const getShopOrSchoolIdFromPageParameters = (url?: string): string | null => {
  const params = parsePageParameters(url)

  for (const paramKey of SHOP_OR_SCHOOL_ID_PAGE_PARAM_NAMES) {
    const keyVal = params.get(paramKey)
    if (keyVal !== undefined) {
      return keyVal
    }
  }

  return null
}
export const setShopOrSchoolIdPageParameter = (schoolId: string | null) => {
  const parameterUpdates: { [key: string]: string | null } = {}

  parameterUpdates[SHOP_OR_SCHOOL_ID_PAGE_PARAM_NAMES[0]] = schoolId
  for (let i = 1; i < SHOP_OR_SCHOOL_ID_PAGE_PARAM_NAMES.length; i++) {
    parameterUpdates[SHOP_OR_SCHOOL_ID_PAGE_PARAM_NAMES[i]] = null
  }

  return updatePageParameters(parameterUpdates)
}

export const serialisePageParameters = (params: Map<string, string>): string => {
  const components: string[] = []

  for (const [key, value] of params.entries()) {
    components.push(
      `${key}=${encodeURIComponent(value)}`
    )
  }

  return (components.length !== 0) ? ('?' + components.join('&')) : ''
}

export const updatePageParameters = (updates: { [key: string]: string | null }) => {
  /*
  * Uses history API to set the parameter in the URL.
  * Null value removes parameter.
  * NB: value will be url encoded.
  */
  const params = parsePageParameters()

  for (const [key, value] of Object.entries(updates)) {
    if (!updates.hasOwnProperty(key)) {
      continue;
    }

    if (value === null) {
      params.delete(key)
    }
    else {
      params.set(key, value)
    }
  }

  const serialisedParams = serialisePageParameters(params)

  let newUrl: string
  if (window.location.search !== '') {
    newUrl = window.location.href.replace(window.location.search, serialisedParams)
  }
  else {
    const [part1, part2] = window.location.href.split('#', 1)
    newUrl = [part1, serialisedParams, part2].join('')
  }
  window.history.replaceState(null, '', newUrl)
}