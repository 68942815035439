export default class KeyAwareDefaultMap<K, V> implements Map<K, V> {
    protected _internalMap = new Map<K, V>();
    protected readonly _defaultBuilder: (key: K) => V;

    constructor(defaultBuilder: (key: K) => V) {
        this._defaultBuilder = defaultBuilder;
    }

    readonly [Symbol.toStringTag]: string = "Map";
    get size(): number {
        return this._internalMap.size;
    };

    [Symbol.iterator](): IterableIterator<[K, V]> {
        return this._internalMap[Symbol.iterator]();
    }

    clear(): void {
        this._internalMap.clear();
    }

    delete(key: K): boolean {
        return this._internalMap.delete(key);
    }

    entries(): IterableIterator<[K, V]> {
        return this._internalMap.entries();
    }

    forEach(callbackfn: (value: V, key: K, map: Map<K, V>) => void, thisArg?: any): void {
        return this._internalMap.forEach(callbackfn, thisArg);
    }

    get(key: K): V {
        if (!this._internalMap.has(key)) {
            this._internalMap.set(key, this._defaultBuilder(key));
        }
        return this._internalMap.get(key)!;
    }

    has(key: K): boolean {
        return this._internalMap.has(key);
    }

    keys(): IterableIterator<K> {
        return this._internalMap.keys();
    }

    set(key: K, value: V): this {
        this._internalMap.set(key, value);
        return this;
    }

    values(): IterableIterator<V> {
        return this._internalMap.values();
    }
}