import { TaintedPattern, validate } from "./tainted";

interface formatCentsPriceForDisplayProps {
  dollarSign?: boolean
}
export const formatCentsPriceForDisplay = (cents: number, props?: formatCentsPriceForDisplayProps): string => {
  const { dollarSign = true } = props ?? {}

  if (dollarSign) {
    return `${(cents < 0) ? '-' : ''}$${formatCentsPriceForDisplay(Math.abs(cents), { dollarSign: false })}`;
  }

  return (cents / 100).toFixed(2);
}


export const tryConvertDollarStringToCents = (inputString: string): number | null => {
  const dollarsString = inputString.replace(/^\$/, '').replace(/\s+/g, '');  // replace the dollar sign at the start, remove space (if present)

  if (!validate(TaintedPattern.dollarDecimal, dollarsString)) {
    return null;
  }

  const [dollarsPart, centsPart = '00'] = dollarsString.split('.')

  let [dollarsNumber, centsNumber] = [parseInt(dollarsPart), parseInt(centsPart)]

  if (isNaN(dollarsNumber) || isNaN(centsNumber) || centsNumber < 0) {
    return null
  }

  if (centsPart.length <= 1) {
    centsNumber *= 10
  }

  return (dollarsNumber * 100) + centsNumber
}

export const cleanUnicodeQuotes = (inputString: string): string => {
  return inputString
    .replace(/\u2018/g, "'")  // Left Single quotation mark
    .replace(/\u2019/g, "'")  // Right Single quotation mark
    .replace(/\u201c/g, '"')  // Left Double quotation mark
    .replace(/\u201d/g, '"')  // Right Double quotation mark
    ;
}