/// An implementation of tainted that builds RegExp objects on-demand and tests against them
import KeyAwareDefaultMap from "./KeyAwareDefaultMap";

export enum TaintedPattern {
    dollarDecimal = "^[0-9]+(\\.[0-9]{1,2})?$",
    productQuestionId = "^[a-zA-Z0-9 -/']{1,50}$",
    productQuestionTitle = "^[ A-Za-z0-9 &@,.:_\\'\\*\\$(/?)!\\+\\-]{1,2000}$",
    userText1024 = "^[A-Za-z0-9 \\.%!&\\)\\(\\-,/:_@#'\\$\\?\\+\t]{1,1024}$",

    productName = "^[a-zA-Z0-9 \\.%!&\\)\\(\\-,/:_@#'\\$\\?\\+\t]{1,80}$",
    productOptionChoice = "^[a-zA-Z0-9()\\.\\-&,' ]{1,255}$",
    mcatName = "^[a-zA-Z0-9 \\\\.%!&)(\\-,/:_@#'$?+\\t]{1,256}$",
    serviceTitle = "^[a-zA-Z0-9 \\.%!&\\)\\(\\-,/:_@#''\\$\\?\\+ ]{1,80}$",  // From PAT_PRODUCT

    text = "^[ A-Za-z0-9 \\.%!&\\)\\(\\-,/:']{1,255}$",
    email = "^[A-Za-z0-9._%+-]+@[A-Za-z0-9-]+(\\.[A-Za-z0-9-]+)+$",
    telephoneNumber = "(^[0-9 /\\-mob]{0,20}$)|\\A\\Z",
    telephoneNumberOnly = "^[0-9 ]{0,15}$",
    telephoneNumberExt = "^[0-9 ]{0,8}$",
    contactName = "(^[a-zA-Z\\'\\- _()\\.]{1,41}$)|\\A\\Z",

    firstName = "^[A-Za-z'\\-_ \\.]{1,255}$",
    lastName = "^[A-Za-z'\\-_ \\.]{1,255}$",
    enrolmentRoomNonMandatory = "^[a-zA-Z0-9 \\.%!&\\)\\(\\-,/:_@#'\\$\\?\\+\t]{0,256}$",
    enrolmentMemberId = "^[ A-Za-z0-9 \\.%!&\\)\\(\\-,/:_@#;']{0,255}$",
    studentAllergy = "^[ A-Za-z0-9 \\.%!&\\)\\(\\-,/:_@#;']{0,100}$",

    password = "^[a-zA-Z0-9\\.!@#$%\\^&\\*()\\-_\\+=~]{6,}$",

    closedAccountEmail = "^CLOSED_.*@(kindo|thegrowthcollective|tgcl).co.nz$",
    disabledAccountEmail = "^DISABLED_.*@(kindo|thegrowthcollective|tgcl).co.nz$",
    room = "^[a-zA-Z0-9 \\.%!&\\)\\(\\-,/:_@#'\\$\\?\\+\t]{1,256}$"
}

const regexCache: KeyAwareDefaultMap<string, RegExp> = new KeyAwareDefaultMap((key) => new RegExp(key))

interface ValidatedExtraProps {
    throwOnInvalid?: boolean
    acceptNull?: boolean
    acceptEmpty?: boolean
}
export function validated(type: TaintedPattern | string, value: string | null | undefined, { throwOnInvalid = true, acceptNull = false, acceptEmpty = false }: ValidatedExtraProps): string | null {
    if (value === null || value === undefined) {
        if (acceptNull || !throwOnInvalid) {
            return null
        }
        else {
            throw new Error(`Invalid value: ${value}`)
        }
    }

    const tester = regexCache.get(type)

    if (value.length === 0 && acceptEmpty) {
        return '';
    }

    if (tester.test(value)) {
        return value;
    }
    else {
        if (throwOnInvalid) {
            throw new Error(`Failed to pass test: ${value}`)
        }
        else {
            return null
        }
    }
}

interface ValidateExtraProps {
    acceptNull?: boolean
    acceptEmpty?: boolean
}
export function validate(type: TaintedPattern | string, value: string | null | undefined, extraProps?: ValidateExtraProps): boolean {
    const { acceptEmpty = false, acceptNull = false } = extraProps ?? {}

    if (value === null || value === undefined) {
        return acceptNull;
    }

    if (value.length === 0 && acceptEmpty) {
        return true;
    }

    const tester = regexCache.get(type)
    return tester.test(value);
}

export const getRegex = (pattern: string): RegExp => {
    return regexCache.get(pattern)
}