import React, {CSSProperties, forwardRef} from 'react'

interface ImageButtonProps {
    children: React.ReactNode
    className?: string
    style?: CSSProperties
    onClick?: () => void
    onBlur?: () => void
    src?: string
    disabled?: boolean
    alt?: string
}
export const ImageButton = forwardRef<HTMLButtonElement, ImageButtonProps>(({children, className, style, onClick, onBlur, src, disabled, alt}: ImageButtonProps, ref) => {
    return (
      <img className={className} style={style} src={src} alt={alt} onClick={onClick} />
    )
})